export const themeLight = {
  background: '#ffffff',
  text: '#000'
};

export const themeDark = {
  background: '#000',
  text: '#fff'
};

const theme = (mode: string) => (mode === 'dark' ? themeDark : themeLight);

export default theme;
